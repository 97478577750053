<template>
  <div class="main_div">
    <div style="height: auto">
      <n-space>
        <NCard style="margin-top: 20px; margin-left: 80px; border: 0px; min-width: 800px">
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 300px; font-weight: bold">
              쿠폰 관리 > 쿠폰 등록
            </div>
          </n-space>
          <div class="content_delimiter_line"></div>
        </NCard>
      </n-space>

      <n-space style="width: 1400px; min-width: 1400px; height: auto">
        <NCard
          style="
            margin-top: 20px;
            margin-left: 100px;
            width: 100%;
            min-width: 1000px;
            height: 100%;
          "
        >
          <n-form ref="formRef" :model="formValue">
            <n-space item-style="display: flex;" align="center">
              <div style="font-size: 20px; width: 200px; font-weight: bold">
                쿠폰 이름
              </div>
              <n-form-item
                path="name"
                :rule="{
                  required: true,
                  trigger: ['blur', 'input'],
                  message: '쿠폰 이름을 입력해주세요',
                }"
              >
                <NInput
                  type="text"
                  placeholder=""
                  v-model:value="formValue.name"
                  style="width: 700px; height: 30px; text-align: left"
                />
              </n-form-item>
            </n-space>
            <div class="content_delimiter_line"></div>

            <n-space item-style="display: flex;" align="center">
              <div style="font-size: 20px; width: 200px; font-weight: bold">발행처</div>
              <n-form-item
                path="publisher"
                :rule="{
                  required: true,
                  trigger: ['blur', 'input'],
                  message: '발행처를 입력해주세요',
                }"
              >
                <!-- <NInput
                  type="text"
                  placeholder=""
                  v-model:value="formValue.publisher"
                  style="width: 700px; height: 30px; text-align: left"
                /> -->

                <n-select
                  style="font-size: 15px; width: 200px"
                  v-model:value="formValue.publisher"
                  :options="publisherValues"
                  placeholder="발행처를 선택해주세요"
                  :disabled="!isAdd"
                  filterable
                >
                  <template #action>
                    <n-button type="primary" style="width: 100%" @click="goToPublisher">
                      발행처 추가하러 가기...
                    </n-button>
                  </template>
                </n-select>
              </n-form-item>
            </n-space>
            <div class="content_delimiter_line"></div>

            <n-space item-style="display: flex;" align="center">
              <div style="font-size: 20px; width: 200px; font-weight: bold">
                쿠폰 타입
              </div>
              <n-form-item
                path="couponType"
                :rule="{
                  required: true,

                  message: '쿠폰 타입을 선택해주세요',
                }"
              >
                <n-select
                  style="font-size: 15px; width: 200px"
                  v-model:value="formValue.couponType"
                  :options="typeValues"
                  placeholder="쿠폰 타입을 선택해주세요"
                  :disabled="!isAdd"
                />
              </n-form-item>
            </n-space>
            <div class="content_delimiter_line"></div>

            <n-space item-style="display: flex;" align="center">
              <div style="font-size: 20px; width: 200px; font-weight: bold">
                쿠폰 기간 (개월/년)
              </div>
              <n-form-item
                path="periodCount"
                :rule="{
                  required: true,

                  message: '쿠폰 기간을 선택해주세요',
                }"
              >
                <NInputNumber
                  placeholder=""
                  v-model:value="formValue.periodCount"
                  style="width: 100px; height: 30px; text-align: left"
                  :step="1"
                  :min="1"
                  :disabled="!isAdd"
                />
              </n-form-item>
              <span style="color: red"> * 숫자만 입력 가능 </span>
            </n-space>
            <div class="content_delimiter_line"></div>

            <n-space item-style="display: flex;" align="center">
              <div style="font-size: 20px; width: 200px; font-weight: bold">
                쿠폰 갯수
              </div>
              <n-form-item
                path="couponCount"
                :rule="{
                  required: true,

                  message: '쿠폰 갯수를 선택해주세요',
                }"
              >
                <NInputNumber
                  placeholder=""
                  v-model:value="formValue.couponCount"
                  style="width: 100px; height: 30px; text-align: left"
                  :step="1"
                  :min="1"
                  :disabled="!isAdd"
                />
              </n-form-item>
              <span style="color: red"> * 숫자만 입력 가능 </span>
            </n-space>
            <div class="content_delimiter_line"></div>

            <n-space item-style="display: flex;" align="center">
              <div style="font-size: 20px; width: 200px; font-weight: bold">기간</div>
              <n-form-item
                path="startDate"
                :rule="{
                required: true,
                trigger: ['blur', 'change'],
                message: '시작일을 선택해주세요',
                validator (rule: FormItemRule, value: string) {
            if (!value) {
              return new Error('Date is required')
            } 
            return true
          },
              }"
              >
                <n-date-picker
                  v-model:value="formValue.startDate"
                  :default-value="Date.now()"
                  :is-date-disabled="startDateDisabled"
                  :disabled="!isAdd"
                />
              </n-form-item>
              <p>~</p>
              <n-form-item
                path="expiryDate"
                :rule="{
                required: true,
                trigger: ['blur', 'change'],
                message: '만료일을 선택해주세요',
                validator (rule: FormItemRule, value: string) {
            if (!value) {
              return new Error('Date is required')
            } 
            return true
          },
              }"
              >
                <n-date-picker
                  v-model:value="formValue.expiryDate"
                  :is-date-disabled="expiryDateDisabled"
                  :disabled="!isAdd"
                />
              </n-form-item>
            </n-space>

            <div class="content_delimiter_line"></div>

            <!-- <n-space item-style="display: flex;" align="center">
              <div style="font-size: 20px; width: 200px; font-weight: bold">
                활성 여부
              </div>
              <n-form-item path="isUse">
                <n-radio-group v-model:value="formValue.isUse" name="isUse">
                  <n-radio :value="true" style="font-size: 15px">활성</n-radio>
                  <n-radio :value="false" style="font-size: 15px">비활성</n-radio>
                </n-radio-group>
              </n-form-item>
            </n-space>
            <div class="content_delimiter_line"></div> -->

            <n-space item-style="display: flex;" align="center">
              <div style="font-size: 20px; width: 200px; font-weight: bold">설명</div>
              <n-form-item path="description">
                <NInput
                  type="textarea"
                  placeholder=""
                  v-model:value="formValue.description"
                  style="width: 900px; height: 200px; text-align: left"
                />
              </n-form-item>
            </n-space>
            <div class="content_delimiter_line"></div>

            <n-space style="margin-top: 50px" justify="center">
              <n-form-item>
                <NButton
                  dashed
                  style="width: 250px; height: 30px; border: none"
                  type="warning"
                  v-on:click="hide"
                >
                  취소
                </NButton>
              </n-form-item>

              <n-form-item>
                <NButton
                  dashed
                  @click="isAdd ? upload() : modify()"
                  style="width: 250px; height: 30px; border: none"
                  type="success"
                  >{{ submitButtonText }}</NButton
                >
              </n-form-item>
            </n-space>
          </n-form>
        </NCard>
      </n-space>
    </div>

    <NModal
      v-model:show="isAlert"
      :mask-closable="false"
      :content="alertMessage"
      :type="alertType"
      preset="dialog"
      title="Dialog"
      positive-text="확인"
      @positive-click="onPositiveClick"
    >
    </NModal>
  </div>
</template>

<script lang="ts">
import { h, defineComponent, ref } from "vue";
import { getCallable, firebaseStorage } from "@/config/env";
import {
  NSpace,
  NCard,
  NButton,
  NIcon,
  NModal,
  NInput,
  commonLight,
  NUpload,
  NForm,
  NDatePicker,
  NFormItem,
  FormItemRule,
  NRadio,
  NRadioGroup,
  NSelect,
  NInputNumber,
} from "naive-ui";
import Loading from "@/components/Loading.vue";
import * as common from "@/assets/common/common";
import * as constant from "@/assets/constant";
import axios from "axios";
import type { UploadInst, FormInst } from "naive-ui";

const addCoupon = getCallable("addCoupon");
const modifyCoupon = getCallable("modifyCoupon");
const getPublishers = getCallable("getPublishers");
export default defineComponent({
  name: "AddCoupon",
  props: ["isAddCoupon", "selectedItem"],
  components: {
    NSpace,
    NInput,
    NCard,
    NButton,
    NIcon,
    NModal,
    NUpload,
    NForm,
    NDatePicker,
    NFormItem,
    Loading,
    NRadio,
    NRadioGroup,
    NSelect,
    NInputNumber,
  },
  watch: {
    isAddCoupon: function (newIsAddCoupon: any) {
      this.sItem = this.selectedItem;

      this.isAdd = this.sItem == undefined;
      if (this.isAdd) {
        this.submitButtonText = "등록";
        this.clearEditor();
      } else {
        this.submitButtonText = "수정";
        this.setDataEditor();
      }
    },
  },
  data() {
    return {
      baseList: [] as any,
      showList: [] as any,
      isAdd: true as boolean,
      isAlert: false as boolean,
      alertType: "",
      alertMessage: "",
      isLoading: false,
      sItem: undefined as any,
      uploadPromotionCodeRef: null,
      formRef: null as any,
      formValue: {
        file: null,
        name: null,
        expiryDate: Date.now(),
        startDate: Date.now(),
        publisher: null,
        description: "",
        couponType: null,
        periodCount: null,
        couponCount: null,
      },
      submitButtonText: "등록",
      publisherValues: undefined as any,
      typeValues: [
        {
          label: "monthly",
          value: 100,
        },
        {
          label: "yearly",
          value: 101,
        },
      ],
      typeValue: null,
    };
  },
  setup() {
    return {
      noSideSpace: (value: string) => !/ /g.test(value),
    };
  },
  beforeUnmount() {},
  mounted() {
    this.formRef = this.$refs.formRef as any;
    this.getPublisherList();
  },
  methods: {
    expiryDateDisabled(ts: number) {
      const date = new Date(ts);
      const startDate = new Date(this.formValue.startDate);
      startDate.setHours(0, 0, 0, 0);
      return date < startDate;
    },
    onPositiveClick() {
      this.isAlert = false;
    },
    hide() {
      this.clearEditor();
      this.$emit("hide");
    },
    modify() {
      this.formRef.validate((errors: any) => {
        if (!errors) {
          this.$emit("changeLoadingStatus", true);
          common.asyncFunction(async () => {
            let data = {
              id: this.sItem.SK,
              name: this.formValue.name,
              publisher: this.formValue.publisher,

              description: this.formValue.description,
            } as any;

            modifyCoupon(data)
              .then(async (result: any) => {
                if (result.data.error) {
                  this.alertMessage = "수정 실패";
                  this.alertType = "error";
                  this.isAlert = true;
                } else {
                  this.alertMessage = "수정 성공";
                  this.alertType = "success";
                  this.isAlert = true;
                  this.hide();
                }
              })
              .catch((error: any) => {
                console.log(error);
              })
              .finally(() => {
                this.$emit("changeLoadingStatus", false);
              });
          });
        } else {
          console.log("Validation failed:", errors);
        }
      });
    },
    upload() {
      this.formRef.validate((errors: any) => {
        if (!errors) {
          this.$emit("changeLoadingStatus", true);
          common.asyncFunction(async () => {
            const expDate = new Date(this.formValue.expiryDate);

            expDate.setHours(23, 59, 59, 0);
            let data = {
              name: this.formValue.name,
              publisherId: this.formValue.publisher,
              startDate: this.formValue.startDate,
              expiryDate: expDate.getTime(),
              description: this.formValue.description,
              type: this.formValue.couponType,
              value: this.formValue.periodCount,
              publisher: this.publisherValues.find(
                (item: any) => item.value === this.formValue.publisher
              ).label,
              count: this.formValue.couponCount,
            } as any;
            addCoupon(data)
              .then(async (result: any) => {
                if (result.data.error) {
                  this.alertMessage = "등록 실패";
                  this.alertType = "error";
                  this.isAlert = true;
                } else {
                  this.alertMessage = "등록 성공";
                  this.alertType = "success";
                  this.isAlert = true;
                  this.hide();
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.$emit("changeLoadingStatus", false);
              });
          });
        } else {
          console.log("Validation failed:", errors);
        }
      });
    },
    contentKoSeclect(e: any) {
      this.formValue.file =
        e.fileList.length > 0 ? e.fileList.map((item: any) => item.file)[0] : null;
      if (this.formRef != null) {
        this.formRef.validate();
      }
    },
    clearEditor() {
      this.formValue.name = null;
      this.formValue.publisher = null;
      this.formValue.startDate = Date.now();
      this.formValue.expiryDate = Date.now();

      this.formValue.description = "";
      this.formValue.couponType = null;
      this.formValue.periodCount = null;
      this.formValue.couponCount = null;
    },
    setDataEditor() {
      console.log(this.sItem);

      if (this.sItem != undefined) {
        this.formValue.name = this.sItem.name == undefined ? null : this.sItem.name;
        this.formValue.publisher =
          this.sItem.publisher == undefined ? null : this.sItem.publisher;
        this.formValue.startDate = Date.parse(this.sItem.parseStartDate);
        this.formValue.expiryDate = Date.parse(this.sItem.parseExpDate);

        this.formValue.description = this.sItem.description;
        this.formValue.couponType = this.sItem.type;
        this.formValue.periodCount = this.sItem.value;
        this.formValue.couponCount = this.sItem.count;
      }
    },
    goToPublisher() {
      this.$emit("changeSelectedHomeView", constant.homeView.Publisher);
    },
    getPublisherList() {
      this.baseList = [];
      this.showList = [];

      common.asyncFunction(async () => {
        getPublishers({})
          .then(async (result: any) => {
            if (result.data.error) {
            } else {
              let i = 0;
              for (let item of result.data.data.Items) {
                let obj = { label: item.name, value: item.SK };

                this.baseList.push(obj);
              }
              this.publisherValues = this.baseList;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      });
    },
  },
});
</script>

<style scope>
.outer {
  position: relative;
}
.inner {
  position: absolute;
}
.content_delimiter_line {
  border: lightgrey 2px solid;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}
</style>
