// import sa from 'vue3-simple-alert-next';
import { getCallable, storageRef, firebaseStorage } from "@/config/env";
import { uploadBytes, deleteObject, getDownloadURL, getMetadata } from 'firebase/storage';

import * as constant from '@/assets/constant';


export const Section1 = "S1" as any;
export const preview = "preview" as any;
export const previewContent = "content#preview" as any
export const giftPreview = "giftPreview" as any;
export const AI = "AI" as any;
export const giftPreviewContent = "content#giftPreview" as any
const checkLoginAuth = getCallable("checkLoginAuth");

export function sleep(ms: any) {
  const loopTime = Date.now() + ms;
  while (Date.now() < loopTime) { }
}

export function asyncFunction(f: any) {
  setTimeout(() => f(), 1);
}

export async function checkLogin() {
  let isLogin = false;
  await checkLoginAuth({})
    .then((result: any) => {
      if (result.data.error) {
        console.log(result.data.error);
        isLogin = false;
      } else {
        isLogin = true;
      }
    })
    .catch((error) => {
      console.log(error);
      isLogin = false;
    });
  return isLogin;
}

// export async function alert(text: any) {
//   sa.alert(text);
// }

// export async function confirm(text: any) {
//   let flag = false;
//   await sa
//     .confirm(text)
//     .then((result) => {
//       flag = result;
//     })
//     .catch(() => {
//       flag = false;
//     });
//   return flag;
// }
export function deepCopy(o: any) {
  return JSON.parse(JSON.stringify(o));
}

export function getDate(dateTime: any) {

  const d = new Date(Number(dateTime));
  let month = (d.getMonth() + 1) as any;
  month = month < 10 ? "0" + month : month;
  let date = d.getDate() as any;
  date = date < 10 ? "0" + date : date;
  let hours = d.getHours() as any;
  hours = hours < 10 ? "0" + hours : hours;
  let minutes = d.getMinutes() as any;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let seconds = d.getSeconds() as any;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return (
    d.getFullYear() +
    "-" +
    month +
    "-" +
    date +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds
  );
}

export function makeFilePath(depth1: any, depth2: any, depth3: any) {
  if (depth3 == undefined) {
    return depth1 + "/" + depth2;
  } else {
    return depth1 + "/" + depth2 + "/" + depth3;
  }
}

export async function uploadFile(file: any, fileName: any, path: any) {
  let uploadFile = file;

  let storagePath = 'content/' + path + '/' + fileName + '.' + "mp3";

  let sref = storageRef(firebaseStorage, storagePath);
  const metadata = {
    contentType: uploadFile.type,
  };

  await uploadBytes(sref, uploadFile, metadata).then((snapshot: any) => {
    console.log('Uploaded an array!');
    console.log(snapshot);
  });

  return storagePath;
}

export async function uploadSample(file: any, fileNamewmp3: any, path: any) {
  let uploadFile = file;

  let storagePath = 'sample/' + path + '/' + fileNamewmp3;

  let sref = storageRef(firebaseStorage, storagePath);
  const metadata = {
    contentType: uploadFile.type,
  };

  await uploadBytes(sref, uploadFile, metadata).then((snapshot: any) => {
    console.log('Uploaded an array!');
    console.log(snapshot);
  });

  return storagePath;
}

export async function deleteFile(path: any) {
  let sref = storageRef(firebaseStorage, path);
  console.log("DELETE FILE");
  console.log(sref);
  try {
    let fileExist = await getMetadata(sref);
    await deleteObject(sref).then((snapshot: any) => {

    }).catch(function (error: any) {
      console.log(error);
    })

  } catch (error) {
    console.log(error);
  }


}

export async function getFileUrl(path: any) {
  let sref = storageRef(firebaseStorage, path);

  let result = undefined;
  try {
    result = result = {
      success: true,
      data: await getDownloadURL(sref),
    };
    // Do whatever
  } catch (err) {
    result = {
      success: false,
      message: err
    };
  }

  return result;
}

export function getFeedbackType(type: any) {
  if (type == constant.feedback_type.ERROR) {
    return "앱 오류 및 불편사항";
  } else if (type == constant.feedback_type.ADD_FUNCTION) {
    return "추가 기능 요청";
  } else if (type == constant.feedback_type.USE) {
    return "이용문의";
  } else if (type == constant.feedback_type.AFFILIATE) {
    return "제휴문의";
  } else if (type == constant.feedback_type.ETC) {
    return "기타";
  }
}

export function getStatusText(type: any) {
  if (type == constant.feedback_status.WAITING) {
    return "답변 대기중";
  } else if (type == constant.feedback_status.COMPLETE) {
    return "답변 완료";
  }
}

export function getIsLockText(isLock: any) {
  if (isLock == true) {
    return "잠금 중";
  } else if (isLock == false) {
    return "사용 중";
  }
}

export function getYYYYMMDD(timestamp: any) {
  let parsedDate = new Date(timestamp);
  let yyyymmdd = "";
  let year = parsedDate.getFullYear().toString();
  let month = (parsedDate.getMonth() + 1).toString();
  let date = parsedDate.getDate().toString();
  (date.length == 1) && (date = '0' + date);
  (month.length == 1) && (month = '0' + month);
  yyyymmdd = year + month + date;
  return yyyymmdd;
}


export function getYYYYMM(timestamp: any) {
  let parsedDate = new Date(timestamp);

  let yyyymm = "";
  let year = parsedDate.getFullYear().toString();
  let month = (parsedDate.getMonth() + 1).toString();
  (month.length == 1) && (month = '0' + month);
  yyyymm = year + month;
  return yyyymm;
}

export function getYYYY(timestamp: any) {
  let parsedDate = new Date(timestamp);
  let yyyy = "";
  let year = parsedDate.getFullYear().toString();
  yyyy = year;
  return yyyy;
}

export function parseContent(content: any) {
  let returnContent = ""
  if (content.length > 11) {
    returnContent = content.substring(0, 10) + " ...";
  } else {
    returnContent = content;
  }
  return returnContent;
}

const langToSysLangMap: { [key: string]: constant.sys_lang_codes } = {
  [constant.lang_codes.EN]: constant.sys_lang_codes.EN,
  [constant.lang_codes.KO]: constant.sys_lang_codes.KO,
  [constant.lang_codes.JA]: constant.sys_lang_codes.JA,
  [constant.lang_codes.ES]: constant.sys_lang_codes.ES,
  [constant.lang_codes.FR]: constant.sys_lang_codes.FR,
};

// Function to get the sys_lang_codes equivalent
export function getSysLangCode(langCode: any): any {
  return langToSysLangMap[langCode];
}

const keyToLangMap: { [key: string]: any } = {
  contentJa: constant.lang_codes.JA,
  contentEs: constant.lang_codes.ES,
  contentFr: constant.lang_codes.FR,
};

// Function to get the sys_lang_codes equivalent
export function getLangCodeFromContentEtc(apiKey: string): any {
  return keyToLangMap[apiKey];
}
