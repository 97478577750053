import { initializeApp } from "firebase/app";
import {
  getFunctions,
  connectFunctionsEmulator,
  httpsCallable,
} from "firebase/functions";
import { getStorage, ref, uploadBytes } from "firebase/storage"
import { firebaseConfig, useEmulator, storageUrl } from "./fb_env";
import KAKAO from "@/assets/js/kakao.min";
import { kakaoConfig } from "@/config/kakao_env";

import {
  getAuth,
  connectAuthEmulator,
  signInWithCustomToken,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

export const Kakao = KAKAO as any;
Kakao.init(kakaoConfig.javaScriptKey);
Kakao.isInitialized();

const app = initializeApp(firebaseConfig);
const functions =
  firebaseConfig.region.trim() != ""
    ? getFunctions(app, firebaseConfig?.region)
    : getFunctions(app);
const auth = getAuth();

export const storage = getStorage(app);

if (useEmulator) connectFunctionsEmulator(functions, "localhost", 5001);
if (useEmulator) connectAuthEmulator(auth, "http://localhost:9099");

export const firebaseApp = app;
export const firebaseFunctions = functions;
export const firebaseAuth = auth;
export const firebaseStorage = storage;
export const storageRef = ref;

export function getCallable(functionName: string) {
  return httpsCallable(firebaseFunctions, functionName);
}

export function getCallableOption(functionName: string) {
  return httpsCallable(firebaseFunctions, functionName, { timeout: 540000 });
}